.App {
  text-align: center;

  /* Add your scrollbar properties here */
  .scrollable-content {
    &::-webkit-scrollbar {
      width: 0.5vw;
    }
    &::-webkit-scrollbar-thumb {
      background-color: black; /* Change thumb color */
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 5px;
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "UberMoveMedium";
  src: url("./theme/uber-move/UberMoveBold.otf") format("opentype");
}
