@font-face {
  font-family: "UberMoveMedium";
  src: url("../../theme/uber-move/UberMoveBold.otf") format("opentype");
}

.language-selector {
  position: relative;
}

.language-list {
  position: absolute;
  top: 100%;
  right: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 10;
}

.language-list li {
  font-family: "UberMoveMedium";
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  font-size: 14px;
}

.language-list li:hover {
  background-color: #f2f2f2;
}

.fi {
  width: 1.5em;
  height: 1em;
  margin-right: 8px;
}

.language-name {
  color: #333;
  margin-left: 8px;
}

.checkmark-icon {
  color: #333;
  margin-left: 0.5em;
}

.globe-icon {
  font-size: 1em;
  color: #333;
}

.language-list li,
.language-list li:hover {
  transition: background-color 0.3s ease;
}
